// @flow

import React, { useState, useCallback, useEffect } from 'react';
import type { Node } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '@riseart/common';
import { Wrapper } from '@riseart/layout';
import { selectVisitorIsSubscribed } from 'shared_services/redux/selectors/visitor';
import { selectMeLoaded } from 'shared_services/redux/selectors/me';
import { CreateMailingListMutation } from 'shared_data/providers/queries/CreateMailingListMutation';
import { SubscribeForm } from 'shared_components/forms/subscribe/Form';

import { emailSubscriptionSectionCls } from 'shared_components/common/subscription/Section.less';

type Props = {
  children: Function,
  title: Node,
  subTitle: Node,
  inputName: string,
  inputId: string,
  buttonKey: string,
  showSuccessDialog?: boolean,
};

/**
 * EmailSubscriptionSection
 *
 * @param {Props} props
 * @returns {Node}
 */
export const EmailSubscriptionSection = ({
  children,
  title,
  subTitle,
  inputName,
  inputId,
  buttonKey,
  showSuccessDialog = false,
}: Props): Node => {
  const isMeLoaded = useSelector(selectMeLoaded);
  const isSubscribed = useSelector(selectVisitorIsSubscribed());
  const [subscribed, setSubscribed] = useState(isSubscribed);
  const handleCloseDialog = useCallback(() => {
    setSubscribed(true);
  }, [setSubscribed]);

  useEffect(() => {
    setSubscribed(isSubscribed);
  }, [isSubscribed]);

  return children({
    isSubscribed: subscribed,
    SubscribeForm:
      !isMeLoaded || subscribed ? null : (
        <Wrapper className={emailSubscriptionSectionCls}>
          {title ? (
            <Heading tag="h3" level="2">
              {title}
            </Heading>
          ) : null}
          {subTitle ? (
            <Heading tag="h4" level="4" uppercase={false}>
              {subTitle}
            </Heading>
          ) : null}
          <div>
            <CreateMailingListMutation>
              {(createMailingList) => (
                <SubscribeForm
                  showSuccessDialog={showSuccessDialog}
                  theme="narrow"
                  buttonKey={buttonKey}
                  inputId={inputId}
                  inputName={inputName}
                  isLabelForm={false}
                  subscribeMutation={createMailingList}
                  {...(showSuccessDialog ? { onCloseDialog: handleCloseDialog } : null)}
                />
              )}
            </CreateMailingListMutation>
          </div>
        </Wrapper>
      ),
  });
};
